<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListTheadColumn from '../../../../components/list/TheadColumn'
import ValidationMessage from '../../../../components/form/ValidationMessage'
import ShowPdf from '../../../../components/common/ShowPdf'
import GripoDialog from '../../../../components/common/Dialog'
import ShowEntry from './ShowEntry'
import EntryStatus from './labels/StatusLabel'
import EntryListItemDiscount from './ListItemDiscount.vue'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import * as fileService from '../../../../services/fileService'
import {getTenantData} from '@app/domains/system/tenant/service'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {confirmDialog, successDialog} from '@utils/flash'

import {mapGetters} from 'vuex'
import {required, requiredIf} from 'vuelidate/lib/validators'
import {clone, cloneDeep, isEmpty} from 'lodash'
import moment from 'moment'

import ChargingNotify, {defaultChargingRules} from './ChargingNotify.vue'

const defaultPortion = {
  single_client: true,
  multiple: false,
  portions: [],
  portionsList: [],
  is_partial_settled: false,
  key: '',
  id: '',
  entry_id: '',
  client_id: '',
  entry: {
    id: '',
    client_id: '',
    client: {
      id: '',
      name: '',
      balance: 0
    }
  },
  payment_method_id: '',
  bank_account_id: '',
  due_date: '',
  due_date_format: '',
  pay_date: '',
  amount_paid: '',
  custom_amount: false,
  discount: '',
  discount_target: 'portion',
  description: '',
  payment_notes: '',
  authorization_code: '',
}

const defaultFilters = {
  type: 'revenue',
  client: '',
  supplier: '',
  status: 'created',
  dates: [helpers.methods.dateFirstDayOfMonth(true), helpers.methods.dateLastDayOfMonth(true)],
  due_date: true,
  show_opened_entries: false,
  include_partial_settled: false,
  payment_method_id: '',
  bank_account_id: '',
  classification_id: '',
  product_id: '',
  has_invoice: 'all',
  deleted: false,
  authorization_code: '',
}

const defaultInvoiceData = {
  enabled: false,
  nfce: false,
  nfce_include_cpf: false,
  nfce_send_notification: false,
  nfse: false,
  nfse_include_cpf: false,
  nfse_send_notification: false,
  entry_portion: {},
  entry_portions: []
}

const defaultNotifyPortion = {
  dialogVisible: false,
  generate_transaction: false,
  entry_id: null,
  entry_portion_id: null,
}

export default {
  components: {
    ListMain,
    ListHeader,
    ListTheadColumn,
    ValidationMessage,
    ShowEntry,
    EntryStatus,
    ShowPdf,
    GripoDialog,
    ChargingNotify,
    EntryListItemDiscount
  },
  mixins: [helpers],
  props: {
    entryType: {
      type: String,
      default: 'revenue'
    },
    title: {
      type: String,
      default: 'Contas a Receber'
    },
    storeTitle: {
      type: String,
      default: 'Criar Lançamento'
    },
    storeSimpleTitle: {
      type: String,
      default: 'Criar Lançamento Simples'
    },
    filtersData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      filters: {...defaultFilters},
      selectedList: [],
      showModalSettledPortion: false,
      showModalGenerateInvoice: false,
      portionShowModalDatePicker: false,
      showModalResumeCustomMessage: false,
      isResettingAmount: false,
      entryIdSelected: null,
      paymentMethodId: '',
      pdfBase64String: '',
      dependencies: {
        entry_status: [],
        payment_methods: [],
        classifications: [],
        products: [],
        bank_accounts: []
      },
      portionAmountToPay: 0,
      portion: {...defaultPortion},
      useBalance: false,
      isPartialSettled: false,
      generateInvoiceData: {...defaultInvoiceData},
      resumeWhatsapp: false,
      resumeCustomMessage: '',
      chargingRules: {
        dialogVisible: false,
        entry_id: null,
        rules: {...defaultChargingRules}
      },
      notifyPortion: {...defaultNotifyPortion},
      editEntry: {
        dialogVisible: false,
        entry_id: '',
        due_date: '',
        description: '',
      },
      planRenewScheduleFixed: {
        exists: false,
        schedule: {
          court: '',
          start_hour: '',
          end_hour: '',
          dates: [],
        },
        start_datetime: '',
        end_datetime: '',
        credits: 0,
        amount: 0,
      }
    }
  },
  validations: {
    portion: {
      payment_method_id: {
        required: requiredIf(function () {
          return this.portionAmountPaidTotal > 0 && !this.useBalance
        })
      },
      pay_date: {required},
      amount_paid: {required}
    },
    editEntry: {
      classification_id: {required},
      due_date: {required},
      amount: {required},
    }
  },
  watch: {
    'portion.discount'() {
      let portionAmountToPay = this.portionAmountToPay
      if (this.useBalance) {
        portionAmountToPay -= this.portion.entry.client.balance
      }
      this.isResettingAmount = true
      this.portion.amount_paid = this.currencyFormatter(portionAmountToPay - this.portionDiscount)
      setTimeout(() => {
        this.isResettingAmount = false
      }, 50)
    },
    'portion.amount_paid'() {
      const amountPaid = this.currencyParsed(this.portion.amount_paid)
      let portionAmountToPay = this.portionAmountToPay
      if (this.useBalance) {
        portionAmountToPay -= this.portion.entry.client.balance
      }
      this.isPartialSettled = ((portionAmountToPay - this.portionDiscount) > amountPaid)
    },
    useBalance(value) {
      let amountPaid = this.portionAmountToPay
      if (value) {
        amountPaid = this.portionAmountToPay - this.portion.entry.client.balance - this.portionDiscount
        if (amountPaid < 0) {
          amountPaid = 0
        }
      } else {
        amountPaid = this.portionAmountToPay - this.portionDiscount
      }
      this.isResettingAmount = true
      this.portion.amount_paid = this.currencyFormatter(amountPaid)
      setTimeout(() => {
        this.isResettingAmount = false
      }, 50)
    },
  },
  computed: {
    ...mapGetters([
      'listStore', 'tenant', 'permissions', 'isCoach',
      'hasCommercialReceiptModule', 'hasCommercialInvoiceModule', 'hasBasicPlan', 'hasProfessionalPlan'
    ]),
    moneyMask() {
      return currencyConfig()
    },
    paymentAccount() {
      return (this.tenant?.has_payment_account ?? false)
    },
    totalAmount: function () {
      return this.listStore.listData.reduce((a, b) => a + (b.amount - (b.entry.discount || 0)), 0)
    },
    totalAmountPaid: function () {
      return this.listStore.listData
          .filter(entryPortion => entryPortion.payment_methods && entryPortion.payment_methods.length)
          .reduce((total, entryPortion) => {
            return total + entryPortion.payment_methods
                .filter(item => item.payment_method.key !== 'saldo')
                .filter(item => {
                  const isBetweenDates = this.filters.dates.length === 2
                      && moment(item.created_at)
                          .isBetween(moment(this.filters.dates[0]), moment(this.filters.dates[1]), 'day', '[]')
                  let isPaymentMethod = true
                  if (this.filters.payment_method_id) {
                    isPaymentMethod = this.filters.payment_method_id === item.payment_method_id
                  }
                  return isBetweenDates && isPaymentMethod
                })
                .reduce((a, b) => a + b.amount, 0)
          }, 0)
    },
    totalAmountToPay: function () {
      return this.totalAmount - this.listStore.listData
          .filter(entryPortion => entryPortion.payment_methods && entryPortion.payment_methods.length)
          .reduce((total, entryPortion) => {
            return total + entryPortion.payment_methods
                .reduce((a, b) => a + b.amount, 0)
          }, 0)
    },
    totalAmountPaidSaldo: function () {
      return this.listStore.listData
          .filter(entryPortion => entryPortion.payment_methods && entryPortion.payment_methods.length)
          .reduce((total, entryPortion) => {
            return total + entryPortion.payment_methods
                .filter(item => item.payment_method.key === 'saldo')
                .reduce((a, b) => a + b.amount, 0)
          }, 0)
    },
    totalAmountPaidCupom: function () {
      return this.listStore.listData
          .filter(entryPortion => entryPortion.payment_methods && entryPortion.payment_methods.length)
          .reduce((total, entryPortion) => {
            return total + entryPortion.payment_methods
                .filter(item => item.payment_method.key === 'cupom')
                .reduce((a, b) => a + b.amount, 0)
          }, 0)
    },
    isAllChecked() {
      return this.listStore.listData.length > 0 && this.selectedList.length === this.listStore.listData.length
    },
    canStoreEntry() {
      return (!this.isRevenue || (this.isRevenue && this.hasProfessionalPlan)) && !this.isCoach
    },
    isRevenue() {
      return this.entryType === 'revenue'
    },
    selectedPortions() {
      return this.listStore.listData
          .filter(entryPortion => this.selectedList.map(item => item.key).includes(entryPortion.key))
    },
    canExportResumeWhatsApp() {
      const portions = [...this.selectedPortions]
      return portions.length > 0
    },
    canStoreInvoice() {
      return (this.hasCommercialReceiptModule || this.hasCommercialInvoiceModule) && this.permissions.some(item => item.name === 'store.invoice')
    },
    canGenerateReceipt() {
      return this.canStoreInvoice && this.hasCommercialReceiptModule
    },
    canGenerateInvoice() {
      return this.canStoreInvoice && this.hasCommercialInvoiceModule
    },
    portionDiscount() {
      return this.currencyParsed(this.portion.discount)
    },
    portionAmountPaidTotal() {
      return this.currencyParsed(this.portion.amount_paid)
    },
    showAuthorizationCode() {
      if (this.portion.payment_method_id) {
        const paymentMethod = this.dependenciesPaymentMethodsFiltered.find(item => item.id === this.portion.payment_method_id)
        let allowedPaymentMethods = ['cartao_de_credito', 'cartao_de_debito', 'pix']
        return allowedPaymentMethods.includes(paymentMethod.key)
      }
      return false
    },
    canBePartialSettled() {
      let portionAmountToPay = this.portionAmountToPay
      if (this.useBalance) {
        portionAmountToPay -= this.portion.entry.client.balance
      }
      return ((portionAmountToPay - this.portionDiscount) > this.currencyParsed(this.portion.amount_paid))
    },
    balanceUsed() {
      if (this.useBalance) {
        const amountLeft = this.portionAmountToPay - this.portionDiscount
        if (this.portion.entry.client.balance > amountLeft) {
          return amountLeft
        }
        return this.portion.entry.client.balance
      }
      return 0
    },
    portionTotalDebit() {
      const amountLeftToPay = this.portionAmountToPay - (this.portionAmountPaidTotal + this.portionDiscount + this.balanceUsed)
      return amountLeftToPay > 0
          ? amountLeftToPay
          : 0
    },
    portionTotalCredit() {
      const amountTotalPaid = this.portionAmountPaidTotal - this.portionAmountToPay - this.portionDiscount + this.balanceUsed
      return amountTotalPaid > 0
          ? amountTotalPaid
          : 0
    },
    dependenciesPaymentMethodsFiltered() {
      return this.dependencies.payment_methods.filter(item => item.key !== 'saldo' && item.key !== 'cupom')
    },
    dependenciesClassificationsFiltered() {
      return this.dependencies.classifications.filter(item => {
        if ((this.hasBasicPlan && !this.hasProfessionalPlan) || this.isCoach) {
          return ['reservas', 'aulas', 'reservas_online', 'planos'].includes(item.key)
        }
        if (!this.hasBasicPlan && !this.hasProfessionalPlan) {
          return ['planos'].includes(item.key)
        }
        return true
      })
    },
    canSettledPortion() {
      let can = true
      if (this.isSubmitted) {
        can = false
      } else if (this.$v.portion.$invalid) {
        can = false
      } else if (this.portionAmountPaidTotal === 0 && !this.balanceUsed) {
        can = false
      } else if (this.currencyParsed(this.portion.amount_paid) > 0 && !this.portion.payment_method_id) {
        can = false
      }
      return can
    },
    nfseCityConfig() {
      return (this.tenant?.settings?.tenant_nf?.nfse?.city_config ?? null)
    },
    nfseCityConfigCpfCnpjObrigatorioNfse() {
      return this.nfseCityConfig?.cpf_cnpj_obrigatorio_nfse ?? false
    }
  },
  async mounted() {
    defaultFilters.type = this.entryType
    await this.getDependencies()

    this.generateInvoiceData.nfce = this.canGenerateReceipt
    this.generateInvoiceData.nfse = this.canGenerateInvoice

    if (!isEmpty(this.filtersData)) {
      this.filters = {...this.filters, ...this.filtersData}
    }
    const searchData = this.prepareSearchData()
    searchData.orderBy = [{column: 'due_date', direction: 'asc'}]
    bus.$emit('list-init', {
      domain: `entry_search/${this.entryType}`,
      searchRoute: false,
      processResponse: this.processListResponse,
      data: searchData
    }, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  methods: {
    processListResponse(list) {
      list.forEach((item) => {
        item.amount_br = this.currencyFormatter(item.amount)
        if (item.amount_paid) {
          item.amount_paid_br = this.currencyFormatter(item.amount)
        }
        item.checked = false
        item.single_client = true
      })
      return list
    },
    getDependencies() {
      return new Promise(resolve => {
        const dependencies = [{domain: 'entry_status'}, {domain: 'payment_method'}, {domain: 'bank_account'}, {
          domain: 'product',
          data: {active: true}
        }]
        if (this.isRevenue) {
          dependencies.push({domain: 'classification_revenue'})
        } else {
          dependencies.push({domain: 'classification_expense'})
        }
        DataService.get(dependencies).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    prepareSearchData() {
      const searchData = {type: defaultFilters.type}
      if (this.isRevenue) {
        if (this.filters.client.length > 0) {
          searchData.client = this.filters.client
        }
      } else {
        if (this.filters.supplier.length > 0) {
          searchData.supplier = this.filters.supplier
        }
      }
      searchData.status = this.filters.status ? this.filters.status : null
      if (this.filters.dates && this.filters.dates.length > 0) {
        let startDate = this.dateFormat(this.filters.dates[0], 'en')
        let endDate = this.dateFormat(this.filters.dates[1], 'en')
        if (startDate.length > 0 && endDate.length > 0) {
          searchData.start_date = startDate
          searchData.end_date = endDate
        }
      }
      searchData.due_date = this.filters.due_date
      searchData.show_opened_entries = this.filters.show_opened_entries
      searchData.include_partial_settled = this.filters.include_partial_settled
      searchData.deleted = this.filters.deleted
      searchData.authorization_code = this.filters.authorization_code
      if (searchData.status === 'settled') {
        searchData.show_opened_entries = false
      }
      if (this.filters.payment_method_id) {
        searchData.payment_method_id = this.filters.payment_method_id
      }
      if (this.filters.bank_account_id) {
        searchData.bank_account_id = this.filters.bank_account_id
      }
      if (this.filters.classification_id) {
        searchData.classification_id = this.filters.classification_id
      }
      if (this.filters.product_id) {
        searchData.product_id = this.filters.product_id
      }
      if (this.filters.has_invoice !== 'all') {
        searchData.has_invoice = this.filters.has_invoice === 'Y'
      }
      return searchData
    },
    onFilter() {
      return new Promise(resolve => {
        const data = this.prepareSearchData()
        bus.$emit('list-filter', data, () => {
          this.resetSelectedList()
          resolve()
        })
      })
    },
    onFilterClean() {
      this.filters = clone(defaultFilters)
      const defaultSearchData = this.prepareSearchData()
      bus.$emit('list-filter-clean', this.filters, defaultSearchData)
    },
    onExportPDF() {
      bus.$emit('show-loader')
      const searchData = this.prepareSearchData()
      restfulService.post('report/entry', 'export-search', null, searchData)
          .then(response => {
            this.pdfBase64String = response.file
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    onExportCSV() {
      bus.$emit('show-loader')
      const data = this.prepareSearchData()
      data.export = 'csv'
      fileService.get(`entry_search/${this.entryType}`, null, null, 'csv', data, false, 'Relatório de Lançamentos')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    checkItem(entryPortion) {
      if (this.selectedList.some(item => item.key === entryPortion.key)) {
        this.selectedList = this.selectedList.filter(item => item.key !== entryPortion.key)
        entryPortion.checked = false
      } else {
        this.selectedList.push({
          id: entryPortion.id,
          entry_id: entryPortion.entry_id,
          due_date: entryPortion.due_date,
          amount: entryPortion.amount,
          key: entryPortion.key,
        })
        entryPortion.checked = true
      }
    },
    checkAll() {
      if (this.isAllChecked) {
        this.resetSelectedList()
      } else {
        this.selectedList = [...this.listStore.listData.filter(entry => !entry.deleted_at).map(item => {
          return {
            id: item.id,
            entry_id: item.entry_id,
            due_date: item.due_date,
            amount: item.amount,
            key: item.key,
          }
        })]
        this.listStore.listData.filter(entry => !entry.deleted_at).forEach(entry => {
          entry.checked = true
        })
      }
    },
    async selectedAction(command) {
      const portions = [...this.selectedPortions]
      if (command === 'Quitar') {
        bus.$emit('show-loader')
        this.planRenewScheduleFixed.exists = false
        this.generateInvoiceData = {...defaultInvoiceData}
        let amount = 0
        portions.map(item => {
          item.custom_amount = false
          amount += item.amount
          if (item.payment_methods && item.payment_methods.length > 0) {
            amount -= item.payment_methods.reduce((a, b) => a + b.amount, 0)
          }
          amount -= item.entry.discount
        })
        this.portion.single_client = portions.length > 0 ? portions.every(item => {
          return item.client_id === portions[0].client_id
        }) : false
        if (this.portion.single_client) {
          this.portion.entry.client = {...portions[0].entry.client}
          this.portion.client_id = portions[0].client_id
        }
        this.portion.custom_amount = false
        this.portion.amount = amount
        this.portion.portionsList = [...portions]
        setTimeout(() => {
          bus.$emit('hide-loader')
          this.openSettlePortion(this.portion, true)
        }, 200)
      } else if (command === 'Exportar Resumo PDF' || command === 'Enviar Resumo Whatsapp') {
        this.resumeWhatsapp = command === 'Enviar Resumo Whatsapp'
        if (this.resumeWhatsapp) {
          const result = await getTenantData()
          if (result) {
            this.resumeCustomMessage = result?.tenant_payment?.entry_portion_notify_custom_message ?? ''
          }
          this.showModalResumeCustomMessage = true
        } else {
          this.onExportResume()
        }
      } else if (command === 'Gerar Nota Fiscal') {
        this.openGenerateInvoicesModal()
      }
    },
    listDropAction(command, item) {
      if (command === 'cancelRecurrent') {
        this.cancelRecurrent(item)
      } else if (command === 'generatePortion') {
        this.generatePortion(item)
      } else if (command === 'updateChargingRules') {
        this.chargingRules.rules = {...defaultChargingRules}
        if (item.entry.charging_rules) {
          this.chargingRules.rules = {...item.entry.charging_rules}
        }
        this.chargingRules.entry_id = item.entry_id
        this.chargingRules.dialogVisible = true
      } else if (command === 'notifyPortion') {
        this.notifyPortion = {...defaultNotifyPortion}
        this.notifyPortion.entry_id = item.entry_id
        this.notifyPortion.entry_portion_id = item.id
        this.notifyPortion.dialogVisible = true
      } else if (command === 'deletePortion') {
        this.deletePortion(item)
      } else if (command === 'generateInvoice') {
        this.generateInvoiceData.entry_portions.splice()
        this.generateInvoiceData.nfce = true
        this.generateInvoiceData.nfce_include_cpf = false
        this.generateInvoiceData.nfce_send_notification = false
        this.generateInvoiceData.nfse = true
        this.generateInvoiceData.nfse_include_cpf = this.nfseCityConfigCpfCnpjObrigatorioNfse
        this.generateInvoiceData.nfse_send_notification = false
        this.generateInvoiceData.entry_portion = {...item}
        this.showModalGenerateInvoice = true
      } else if (command === 'showInvoice') {
        this.showInvoice(item)
      } else if (command === 'editEntry') {
        this.isResettingAmount = true
        this.editEntry = {...this.editEntry, ...item}
        this.editEntry.amount = this.currencyFormatter(this.editEntry.amount)
        setTimeout(() => {
          this.editEntry.dialogVisible = true
          this.isResettingAmount = false
        }, 100)
      }
    },
    getPortionClientPhone(entryPortion) {
      return entryPortion.entry.client?.phone ?? entryPortion.entry.client?.user?.phone ?? null
    },
    resetSelectedList() {
      this.listStore.listData.forEach(entry => {
        entry.checked = false
      })
      this.selectedList.splice(0)
    },
    async cancelRecurrent(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente cancelar a recorrência desse lançamento?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {
          date: item.due_date,
          entry_portion_id: item.id
        }
        restfulService.put(`entry/${this.entryType}`, 'cancel-recurrent', item.entry_id, data)
            .then(() => {
              notifyService.success({message: 'Recorrência cancelada'})
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async generatePortion(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente gerar a cobrança desse lançamento recorrente?',
        icon: 'warning'
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {
          due_date: item.due_date,
          entry_portion_id: item.id,
          amount: item.amount,
          description: item.description,
        }
        restfulService.put(`entry/${this.entryType}`, 'generate-portion', item.entry_id, data)
            .then(() => {
              notifyService.success({message: 'Cobrança gerada com sucesso'})
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async deletePortion(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente remove esse lançamento?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put(`entry_portion/${this.entryType}`, 'destroy', item.id)
            .then(() => {
              notifyService.success({message: 'Lançamento removido'})
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    handleGenerateInvoice() {
      if (this.generateInvoiceData.entry_portions.length) {
        this.onGenerateInvoices()
      } else {
        this.generateInvoice()
      }
    },
    handleSendResume() {
      this.onExportResume()
    },
    async generateInvoice() {
      this.isSubmitted = true
      bus.$emit('show-loader')
      const data = {
        nfce: this.generateInvoiceData.nfce,
        nfce_include_cpf: this.generateInvoiceData.nfce_include_cpf,
        nfce_send_notification: this.generateInvoiceData.nfce_send_notification,
        nfse: this.generateInvoiceData.nfse,
        nfse_include_cpf: this.generateInvoiceData.nfse_include_cpf,
        nfse_send_notification: this.generateInvoiceData.nfse_send_notification,
      }
      restfulService.put(`entry_portion/${this.entryType}`, 'generate-invoice', this.generateInvoiceData.entry_portion.id, data)
          .then(({message}) => {
            notifyService.success({message})
            this.isSubmitted = false
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    async showInvoice(item) {
      bus.$emit('show-loader')
      restfulService.post('invoice', 'show', item.id)
          .then(response => {
            this.pdfBase64String = response.base64
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    handleModalSettledPortion() {
      this.showModalSettledPortion = !this.showModalSettledPortion
    },
    async openSettlePortion(portion, multiple) {
      bus.$emit('show-loader')
      this.$v.portion.$reset()
      this.planRenewScheduleFixed.exists = false
      this.isResettingAmount = true
      this.generateInvoiceData = {...defaultInvoiceData}
      this.portion = cloneDeep({...defaultPortion, ...portion})
      this.portion.custom_amount = false
      this.portion.multiple = multiple
      this.portion.payment_method_id = ''
      this.portion.bank_account_id = ''
      this.portion.payment_notes = ''
      this.portion.discount = '0,00'
      this.useBalance = false
      this.isPartialSettled = false
      this.portion.pay_date = this.dateTimeNow(false, 'en')
      if (portion.single_client && this.isRevenue) {
        const response = await restfulService.post('client', 'financial', portion.client_id, {})
        this.portion.entry.client.balance = response.balance
        if (portion?.classification?.key === 'planos') {
          const clientPlan = await restfulService.post('entry_portion/revenue', 'plan-renew', null, {
            due_date: portion.due_date,
            entry_id: portion.entry_id,
            portion_id: portion.id,
          })
          if (clientPlan.amount > 0) {
            this.portion.custom_amount = true
          }
          this.portion.amount = clientPlan.amount
          this.portion.amount_paid = this.currencyFormatter(clientPlan.amount)
          this.planRenewScheduleFixed = {...this.planRenewScheduleFixed, ...clientPlan}
          this.planRenewScheduleFixed.exists = !!this.planRenewScheduleFixed.start_datetime && !!this.planRenewScheduleFixed.end_datetime
        }
      }
      let amountToPay = this.portion.amount
      if (portion.entry && portion.entry.discount > 0) {
        this.portion.discount = this.currencyFormatter(portion.entry.discount)
      }
      if (portion.payment_methods && portion.payment_methods.length > 0) {
        amountToPay -= portion.payment_methods.reduce((a, b) => a + b.amount, 0)
      }
      this.portionAmountToPay = amountToPay
      this.portion.amount_paid = this.currencyFormatter(amountToPay)
      setTimeout(() => {
        this.isResettingAmount = false
        this.handleModalSettledPortion()
        bus.$emit('hide-loader')
      }, 200)
    },
    async settledPortion() {
      let title = this.portion.multiple ? 'Deseja realmente quitar os lançamentos selecionados?' : 'Deseja realmente quitar esse lançamento?'
      let text = null
      if (this.portionTotalCredit > 0) {
        text = 'O valor pago informado é maior que o valor total a pagar, essa ação irá gerar para o cliente um crédito de: ' + this.currencyFormatter(this.portionTotalCredit)
      }
      const {value: confirm} = await confirmDialog({
        title: title,
        text: text,
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = cloneDeep({...this.portion, ...{payment_methods: []}})
        delete data.entry
        delete data.portionsList
        data.amount_paid = this.currencyParsed(data.amount_paid)
        data.discount = this.currencyParsed(data.discount)
        if (this.useBalance && this.balanceUsed > 0) {
          data.use_balance = true
          const paymentMethodSaldo = this.dependencies.payment_methods.find(item => item.key === 'saldo')
          data.payment_methods.push({
            payment_method_id: paymentMethodSaldo.id,
            amount: this.balanceUsed,
          })
        }
        if (data.amount_paid > 0 && data.payment_method_id) {
          data.payment_methods.push({
            payment_method_id: data.payment_method_id,
            bank_account_id: data.bank_account_id,
            authorization_code: data.authorization_code,
            amount: data.amount_paid,
          })
        }
        if (this.portion.multiple) {
          data.portions = [...this.selectedList]
          data.discount = 0
        } else {
          data.portions = [{
            id: data.id,
            entry_id: data.entry_id,
            due_date: data.due_date,
            amount: data.amount,
          }]
        }
        data.amount = data.amount_paid
        data.generate_invoice = {...this.generateInvoiceData}
        if (this.isPartialSettled) {
          data.generate_invoice.nfce = false
          data.generate_invoice.nfse = false
        }
        data.generate_credit = this.portionTotalCredit > 0
        if (data.custom_amount) {
          data.plan_renew = {...this.planRenewScheduleFixed}
        }
        restfulService.post(`entry_portion/${this.entryType}`, 'finish', null, data)
            .then(() => {
              this.isSubmitted = false
              this.handleModalSettledPortion()
              this.onFilter()
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async revertPortion(item, paymentMethod) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente reverter o lançamento selecionado?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {entry_portion_payment_id: paymentMethod?.id || null}
        restfulService.put(`entry_portion/${this.entryType}`, 'revert', item.id, data)
            .then(response => {
              notifyService.success({message: response.message})
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    showPortion(item) {
      this.entryIdSelected = item.entry_id
    },
    portionShowOptions(item) {
      return (item.entry && item.status === 'settled' && this.canStoreInvoice)
          || (item.entry && item.entry.recurrent)
          || (item.entry && item.status === 'created' && !item.deleted_at)
    },
    onExportResume() {
      bus.$emit('show-loader')
      const data = {
        type: this.entryType,
        entry_portions: [...this.selectedList],
        custom_message: this.resumeCustomMessage,
        whatsapp: this.resumeWhatsapp,
      }
      restfulService.post(`entry_portion/${this.entryType}`, 'resume', null, data)
          .then(response => {
            if (this.resumeWhatsapp) {
              this.showModalResumeCustomMessage = false
              successDialog({
                title: 'Resumo enviado pelo Whatsapp!',
              })
            } else {
              this.pdfBase64String = response.file
            }
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    openGenerateInvoicesModal() {
      this.generateInvoiceData.entry_portions = [...this.selectedList]
      this.generateInvoiceData.nfce = this.canGenerateReceipt
      this.generateInvoiceData.nfce_include_cpf = false
      this.generateInvoiceData.nfce_send_notification = false
      this.generateInvoiceData.nfse = this.canGenerateInvoice
      this.generateInvoiceData.nfse_include_cpf = this.nfseCityConfigCpfCnpjObrigatorioNfse
      this.generateInvoiceData.nfse_send_notification = false
      this.showModalGenerateInvoice = true
    },
    onGenerateInvoices() {
      bus.$emit('show-loader')
      const data = {
        type: this.entryType,
        entry_portions: [...this.selectedList],
        nfce: this.generateInvoiceData.nfce,
        nfce_include_cpf: this.generateInvoiceData.nfce_include_cpf,
        nfce_send_notification: this.generateInvoiceData.nfce_send_notification,
        nfse: this.generateInvoiceData.nfse,
        nfse_include_cpf: this.generateInvoiceData.nfse_include_cpf,
        nfse_send_notification: this.generateInvoiceData.nfse_send_notification,
      }
      restfulService.post(`entry_portion/${this.entryType}`, 'generate-invoices', null, data)
          .then(({message}) => {
            notifyService.success({message})
            this.showModalGenerateInvoice = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    exportCommand(command) {
      if (command === 'pdf') {
        this.onExportPDF()
      } else if (command === 'csv') {
        this.onExportCSV()
      }
    },
    selectPaymentMethod(paymentMethod) {
      this.portion.bank_account_id = ''
      if (paymentMethod) {
        const bankAccount = this.dependencies.bank_accounts.find(item => item.default_payment_method_id === paymentMethod)
        if (bankAccount) {
          this.portion.bank_account_id = bankAccount.id
        }
        this.portion.authorization_code = ''
      }
    },
    showPaymentMethods(entryPortion) {
      return entryPortion.payment_methods.map(item => {
        const isBetweenDates = this.filters.dates.length === 2
            && moment(item.created_at)
                .isBetween(moment(this.filters.dates[0]), moment(this.filters.dates[1]), 'day', '[]')
        let isPaymentMethod = true
        if (this.filters.payment_method_id) {
          isPaymentMethod = this.filters.payment_method_id === item.payment_method_id
        }
        return {
          id: item.id,
          description: item.payment_method.description,
          amount: item.amount,
          visible: item.notes !== null && item.notes !== '',
          notes: item.notes,
          pay_datetime: item.created_at,
          authorization_code: item.authorization_code,
          not_included: !isBetweenDates || !isPaymentMethod,
          settled_by_user: item.settled_by_user ?? null,
          log: item.log ?? null,
          teller: item.teller ?? null,
        }
      })
    },
    getPortionPartialSettledAmountToPay(entryPortion) {
      let amountToPay = entryPortion.amount
      if (entryPortion.entry && entryPortion.entry.discount > 0) {
        amountToPay -= entryPortion.entry.discount
      }
      if (entryPortion.payment_methods && entryPortion.payment_methods.length > 0) {
        amountToPay -= entryPortion.payment_methods.reduce((a, b) => a + b.amount, 0)
      }
      return amountToPay
    },
    async updateChargingRules() {
      this.isSubmitted = true
      bus.$emit('show-loader')
      const data = {charging_rules: this.chargingRules.rules.enabled ? this.chargingRules.rules : null}
      restfulService.put(`entry/${this.entryType}`, null, this.chargingRules.entry_id, data)
          .then(() => {
            notifyService.success()
            this.isSubmitted = false
            this.chargingRules.dialogVisible = false
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    async onNotifyPortion() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {...this.notifyPortion}
      restfulService.post(`entry_portion/${this.entryType}`, 'notify', null, data)
          .then(() => {
            notifyService.success()
            this.isSubmitted = false
            this.notifyPortion.dialogVisible = false
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    async onEditEntry() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente alterar esse lançamento?',
        icon: 'warning'
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = {...this.editEntry}
        data.amount = this.currencyParsed(data.amount)
        restfulService.put(`entry/${this.entryType}`, null, data.entry_id, data)
            .then(() => {
              notifyService.success()
              this.isSubmitted = false
              this.editEntry.dialogVisible = false
              this.onFilter()
            })
            .catch(e => {
              console.log(e)
              this.isSubmitted = false
              bus.$emit('hide-loader')
            })
      }
    },
  }
}
</script>

<template>
  <div>

    <ListHeader :title="title"
                :route-name="`entry.${entryType}.store`"
                :store-title="storeTitle"
                :check-permission="false"
                :show-store-button="canStoreEntry"
                :force-check-permission="true"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
              <el-dropdown class="ml-2" @command="exportCommand">
                <el-button type="info" size="small">
                  <span>Exportar</span><i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="pdf">
                    <i class="fas fa-file-pdf"></i>
                    <span class="ml-2">Para PDF</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="csv">
                    <i class="fas fa-file-csv"></i>
                    <span class="ml-2">Para Excel/CSV</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Cliente" v-if="isRevenue">
              <el-input v-model="filters.client"
                        placeholder="Filtrar por Cliente"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Fornecedor" v-else>
              <el-input v-model="filters.supplier"
                        placeholder="Filtrar por Fornecedor"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Período">
              <el-date-picker
                  v-model="filters.dates"
                  type="daterange"
                  format="dd/MM/yyyy"
                  placeholder="Filtrar por vencimento">
              </el-date-picker>
              <div class="text-center pt-3">
                <el-form-item>
                  <el-radio v-model="filters.due_date" :label="true">Dt. Vencimento</el-radio>
                  <el-radio v-model="filters.due_date" :label="false">Dt. Pagamento</el-radio>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         filterable
                         clearable
                         placeholder="Filtrar por Status"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.entry_status"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
              <el-form-item label="Mostrar Lançamentos Antigos" style="display: block;"
                            v-if="filters.status !== 'settled'">
                <el-switch v-model="filters.show_opened_entries"
                           :active-value="true"
                           :inactive-value="false"
                           active-text="SIM"
                           inactive-text="NÃO">
                </el-switch>
              </el-form-item>
              <el-form-item label="Incluir Quitação Parcial" style="display: block;"
                            v-if="filters.status === 'settled'">
                <el-switch v-model="filters.include_partial_settled"
                           :active-value="true"
                           :inactive-value="false"
                           active-text="SIM"
                           inactive-text="NÃO">
                </el-switch>
              </el-form-item>
            </el-form-item>
            <el-form-item label="Forma de Pagamento">
              <el-select v-model="filters.payment_method_id"
                         filterable
                         clearable
                         placeholder="Filtrar por Forma de Pagamento"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependenciesPaymentMethodsFiltered"
                           :key="item.id"
                           :label="item.description"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Conta Bancária" v-if="hasProfessionalPlan && !isCoach">
              <el-select v-model="filters.bank_account_id"
                         filterable
                         clearable
                         placeholder="Filtrar por Conta Bancária"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.bank_accounts"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Classificação">
              <el-select v-model="filters.classification_id"
                         filterable
                         clearable
                         placeholder="Filtrar por Classificação"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependenciesClassificationsFiltered"
                           :key="item.id"
                           :label="item.description"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Produto" v-if="isRevenue && hasProfessionalPlan && !isCoach">
              <el-select v-model="filters.product_id"
                         filterable
                         clearable
                         placeholder="Filtrar por Produto"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.products"
                           :key="item.id"
                           :label="item.description"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Nota Fiscal Gerada" v-if="isRevenue && filters.status === 'settled'">
              <el-select v-model="filters.has_invoice">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Sim" value="Y"/>
                <el-option label="Não" value="N"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Código de Autorização/Pagamento" v-if="isRevenue && hasProfessionalPlan && !isCoach">
              <el-input v-model="filters.authorization_code"
                        placeholder="Filtrar por código de autorização/pagamento"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Filtrar por Lançamentos Deletados" v-if="hasBasicPlan || hasProfessionalPlan">
              <el-switch v-model="filters.deleted"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="headerTitle">
        <span>Lançamentos</span>
        <div class="entries-selected d-inline-block">
          <el-dropdown :disabled="selectedList.length === 0"
                       @command="selectedAction">
            <el-button :type="selectedList.length === 0 ? 'default' : 'primary'" size="small">
              <span>Com selecionados:</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Quitar">
                <i class="far fa-money-bill-alt"></i>
                <span class="ml-2">Quitar</span>
              </el-dropdown-item>
              <el-dropdown-item command="Enviar Resumo Whatsapp" v-if="isRevenue" :disabled="!canExportResumeWhatsApp">
                <i class="fab fa-whatsapp"></i>
                <span class="ml-2">Enviar Resumo Whatsapp</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="isRevenue && canStoreInvoice && !isCoach"
                                command="Gerar Nota Fiscal">
                <i class="fas fa-file-invoice-dollar"></i>
                <span class="ml-2">Gerar Nota Fiscal</span>
              </el-dropdown-item>
              <el-dropdown-item command="Exportar Resumo PDF">
                <i class="fas fa-file-pdf"></i>
                <span class="ml-2">Exportar Resumo PDF</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div slot="headerList">
        <div class="legends">
          <div class="legend-entry">
            <span>Legendas:</span>
          </div>
          <div class="legend-entry">
            <span class="label label-default"></span>
            <span>Aguardando pgto.</span>
          </div>
          <div class="legend-entry">
            <span class="label label-danger"></span>
            <span>Em Atraso</span>
          </div>
          <div class="legend-entry">
            <span class="label label-success"></span>
            <span>Quitada</span>
          </div>
          <div class="legend-entry">
            <span class="label label-warning"></span>
            <span>Quitada com Atraso</span>
          </div>
        </div>
      </div>

      <div slot="list" v-if="!isLoadingData">
        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-check">
              <el-checkbox @change="checkAll" :value="isAllChecked"/>
            </th>
            <th>
              <list-thead-column field="due_date"
                                 label="Vencimento"
                                 @update="onFilter"/>
            </th>
            <th>{{ isRevenue ? 'Cliente' : 'Fornecedor' }}</th>
            <th>Descrição</th>
            <th align="right">
              <list-thead-column field="amount"
                                 label="Valor"
                                 @update="onFilter"/>
            </th>
            <th align="right">
              <list-thead-column field="amount_paid"
                                 label="Valor Pg."
                                 @update="onFilter"/>
            </th>
            <th class="text-center">
              <list-thead-column field="updated_at"
                                 label="Quitado em"
                                 @update="onFilter"/>
            </th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData"
              :class="{'row-danger': item.overdue}">
            <th class="list-table-check">
              <el-checkbox @change="checkItem(item)" v-model="item.checked" :disabled="!!item.deleted_at"/>
            </th>
            <td class="text-center">
              <el-popover
                  placement="top-start"
                  trigger="hover"
                  :close-delay="50"
                  :disabled="!item.created_at && !item?.created_by_user && !item.deleted_at">
                <div slot="reference">
                  <div>
                    {{ item.due_date | dateEnToBr }}
                  </div>
                  <div>
                    <entry-status :item="item"
                                  :status="item.status"
                                  :deleted="!!item.deleted_at"
                                  show-dot/>
                  </div>
                  <div class="entry-status mt-1"
                       v-if="item.status === 'created' && item.partial_settled && getPortionPartialSettledAmountToPay(item) > 0">
                    <el-tooltip effect="dark"
                                content="Quitado Parcialmente"
                                placement="top">
                      <el-tag size="mini" type="danger" effect="dark">
                        Quitado Parc.
                      </el-tag>
                    </el-tooltip>
                  </div>
                </div>
                <div slot="default">
                  <div v-if="item?.created_by_user">Criado por: <strong>{{ item?.created_by_user?.name || '' }}</strong>
                  </div>
                  <div v-if="item.created_at">
                    Criado em: <strong>{{ item.created_at | dateTimeEnToBr }}h</strong>
                  </div>
                  <div v-if="item.deleted_at">
                    <div>Deletado em: <strong>{{ item.deleted_at | dateTimeEnToBr }}</strong></div>
                    <div>Deletado por: <strong>{{ item?.deleted_by_user?.name || '' }}</strong></div>
                  </div>
                </div>
              </el-popover>
            </td>
            <td>
              <template v-if="isRevenue">
                <span v-if="getPortionClientPhone(item)"
                      :title="getPortionClientPhone(item)">
                  <el-tooltip effect="dark"
                              :content="getPortionClientPhone(item)"
                              placement="top">
                    <i class="fab fa-whatsapp"></i>
                  </el-tooltip>
                </span>
                {{ item.entry && item.entry.client ? item.entry.client.name : '' }}
              </template>
              <template v-else>
                {{ item.entry && item.entry.supplier ? item.entry.supplier.name : '' }}
              </template>
            </td>
            <td style="max-width: 300px; word-break: break-word;">
              <strong>{{ item.entry.classification.description }}</strong>
              <el-tooltip effect="dark"
                          placement="right"
                          :content="entryRecurrentLabel(item.entry.interval, item.entry.interval_type)"
                          :disabled="!item.entry.recurrent">
                <span class="ml-1 fs-12" v-if="item.entry.recurrent">
                  <i class="fas fa-redo"></i>
                </span>
              </el-tooltip>
              <br>
              <template v-if="item.description">
                <span class="safe-html" v-html="item.description"></span>
              </template>
              <template v-else>
                <span class="safe-html" v-html="item.entry.description"></span>
              </template>
            </td>
            <td align="right" width="120">
              <div class="currency-amount">{{ item.amount | currencyFormatter }}</div>
              <entry-list-item-discount :amount="item.amount"
                                        :id="item.id"
                                        :recurrent="item.entry.recurrent"
                                        :discount="item.entry.discount"
                                        :status="item.status"
                                        :payments="item.payment_methods"/>
              <div v-if="item.entry.portions_number > 1">
                <strong>Parcela {{ item.portion }}/{{ item.entry.portions_number }}</strong>
              </div>
            </td>
            <td align="right" width="140">
              <div class="currency-amount" v-if="item.payment_methods && item.payment_methods.length > 0">
                <div v-for="paymentMethod in showPaymentMethods(item)">
                  <el-popover
                      placement="top-start"
                      trigger="hover"
                      :close-delay="50">
                    <div slot="reference" :class="['mb-1', {'payment-other-date': paymentMethod.not_included}]">
                      <span class="mr-1" v-if="paymentMethod.not_included">
                        <i class="far fa-calendar-alt"></i>
                      </span>
                      <strong>
                        {{ paymentMethod.amount | currencyFormatter }} ({{ paymentMethod.description }})
                      </strong>
                      <el-button type="warning"
                                 size="small"
                                 circle
                                 class="el-button--extra-small-circle"
                                 v-if="item.status === 'settled' || (item.status === 'created' && item.partial_settled)"
                                 @click="revertPortion(item, paymentMethod)"
                                 title="Reverter Pagamento">
                        <i class="fas fa-retweet"></i>
                      </el-button>
                    </div>
                    <div slot="default">
                      <div v-if="paymentMethod?.settled_by_user || paymentMethod?.log?.user?.name || false">
                        Pago por: <strong>{{
                          paymentMethod?.settled_by_user?.name || paymentMethod?.log?.user?.name || ''
                        }}</strong>
                      </div>
                      <div>
                        Pago em: <strong>{{ paymentMethod.pay_datetime | dateTimeEnToBr }}h</strong>
                      </div>
                      <div v-if="paymentMethod.authorization_code">
                        Cód. de Aut.: {{ paymentMethod.authorization_code }}
                      </div>
                      <div v-if="paymentMethod.visible">
                        {{ paymentMethod.notes }}
                      </div>
                      <div v-if="paymentMethod.teller">
                        Caixa: <strong>{{ paymentMethod.teller.name }}</strong>
                      </div>
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="entry-status mt-1"
                   v-if="item.status === 'created' && item.partial_settled && getPortionPartialSettledAmountToPay(item) > 0">
                <div class="text-danger">
                  <strong>{{ getPortionPartialSettledAmountToPay(item) | currencyFormatter }} (Pendente)</strong>
                </div>
              </div>
            </td>
            <td class="text-center" width="100">
                <span v-if="item.status === 'settled'">
                  <template v-if="item.pay_datetime">
                    {{ item.pay_datetime | dateTimeEnToBr }}h
                  </template>
                  <template v-else>
                    {{ item.pay_date | dateEnToBr }} {{ item.updated_at | timeEnToBr }}h
                  </template>
                </span>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="success"
                           size="small"
                           key="settlePortion"
                           v-if="item.status === 'created' && !item.deleted_at"
                           @click="openSettlePortion(item, false)"
                           title="Quitar">
                  <i class="far fa-money-bill-alt"></i>
                </el-button>
                <el-button type="default"
                           size="small"
                           @click="showPortion(item)"
                           title="Detalhes do Lançamento">
                  <i class="fas fa-search"></i>
                </el-button>
                <el-dropdown @command="listDropAction($event, item)"
                             class="ml-1"
                             style="margin-top: 1px;"
                             v-if="portionShowOptions(item)">
                  <el-button type="info" size="small" title="Mais ações">
                    <i class="fas fa-ellipsis-v"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="generateInvoice"
                                      v-if="isRevenue && item.entry && item.status === 'settled' && canStoreInvoice && !isCoach">
                      <i class="fas fa-file-invoice-dollar"></i>
                      <span class="ml-2">Gerar Nota (NFCe / NFSe)</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="deletePortion"
                                      v-if="item.entry && item.status === 'created' && !item.entry.recurrent && !item.deleted_at">
                      <i class="fas fa-trash"></i>
                      <span class="ml-2">Remover Lançamento</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="notifyPortion"
                                      title="Notifica Cliente pelo Whatsapp"
                                      v-if="isRevenue && item.status === 'created' && !item.deleted_at">
                      <i class="fab fa-whatsapp"></i>
                      <span class="ml-2">Notificar Cliente</span>
                    </el-dropdown-item>
                    <template v-if="item.entry && item.entry.recurrent && !item.deleted_at">
                      <el-dropdown-item command="generatePortion" v-if="!item.id">
                        <i class="fas fa-dollar-sign"></i>
                        <span class="ml-2">Gerar Cobrança</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="editEntry">
                        <i class="fas fa-edit"></i>
                        <span class="ml-2">Alterar Lançamento</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="cancelRecurrent">
                        <i class="fas fa-trash"></i>
                        <span class="ml-2">Cancelar Recorrência</span>
                      </el-dropdown-item>
                      <el-dropdown-item command="updateChargingRules" v-if="item.entry.type === 'revenue'">
                        <i class="fas fa-cash-register"></i>
                        <span class="ml-2">Configurar Cobrança Automática</span>
                      </el-dropdown-item>
                    </template>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th colspan="4" align="right">Total:</th>
            <th align="right">{{ totalAmount | currencyFormatter }}</th>
            <th align="right">
              {{ totalAmountPaid | currencyFormatter }}
            </th>
            <th v-if="isRevenue"></th>
            <th colspan="2"></th>
          </tr>
          <tr v-if="totalAmountPaidSaldo > 0">
            <th colspan="5" align="right">Total Pago c/ Saldo:</th>
            <th align="right">{{ totalAmountPaidSaldo | currencyFormatter }}</th>
            <th v-if="isRevenue"></th>
            <th colspan="2"></th>
          </tr>
          <tr v-if="totalAmountPaidCupom > 0">
            <th colspan="5" align="right">Total Pago c/ Cupom:</th>
            <th align="right">{{ totalAmountPaidCupom | currencyFormatter }}</th>
            <th v-if="isRevenue"></th>
            <th colspan="2"></th>
          </tr>
          <tr v-if="totalAmountToPay > 0">
            <th colspan="5" align="right"></th>
            <th align="right" class="text-danger">
              {{ totalAmountToPay | currencyFormatter }} (Pendente)
            </th>
            <th :colspan="isRevenue ? 3 : 2"></th>
          </tr>
          </tfoot>
        </table>

      </div>

    </ListMain>

    <show-pdf :base64="pdfBase64String"/>

    <gripo-dialog :model.sync="showModalSettledPortion"
                  width="80vw">
      <template v-slot:header>
        <span>
          Quitar Lançamentos
        </span>
      </template>
      <template v-slot:content>
        <div v-if="portion.single_client && isRevenue">
          <div class="flex-c -jc-sa mb-4">
            <div class="text-center">
              <div class="fs-18">
                <strong>Valor Total a Pagar:</strong> {{ portionAmountToPay | currencyFormatter }}
              </div>
              <div class="fs-18">
                <strong>Cliente:</strong> {{ portion.entry.client.name }}
              </div>
            </div>
            <div :class="['use-balance-card', {'-selected': useBalance}]" v-if="portion.entry.client.balance > 0">
              <div class="text">
                <strong>Saldo Disponível:</strong>
                <span class="amount ml-1">{{ portion.entry.client.balance | currencyFormatter }}</span>
              </div>
              <div class="action">
                Usar saldo?
                <el-switch v-model="useBalance"
                           :active-value="true"
                           active-text="SIM"
                           :inactive-value="false"
                           inactive-text="NÃO">
                </el-switch>
              </div>
            </div>
          </div>
        </div>
        <el-form :model="portion" label-position="top">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="Forma de Pagamento"
                                :class="[{'is-required': !(portionAmountPaidTotal === 0 && useBalance)}, { 'el-form-item--error': $v.portion.payment_method_id.$error }]">
                    <v-select :items="dependenciesPaymentMethodsFiltered"
                              v-model="portion.payment_method_id"
                              @input="$v.portion.payment_method_id.$touch()"
                              @blur="$v.portion.payment_method_id.$touch()"
                              @change="selectPaymentMethod"
                              :disabled="portionAmountPaidTotal === 0 && useBalance"
                              :error-messages="validationMessageField($v.portion.payment_method_id)"
                              item-text="description"
                              item-value="id"
                              single-line
                              outline
                              hide-details
                              :placeholder="portionAmountPaidTotal === 0 && useBalance ? 'Utilizado saldo' : 'Forma de pagamento'"
                              no-data-text="Nenhum registro encontrado"
                              clearable/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Conta Bancária">
                    <v-select :items="dependencies.bank_accounts"
                              v-model="portion.bank_account_id"
                              :disabled="portionAmountPaidTotal === 0 && useBalance"
                              item-text="name"
                              item-value="id"
                              single-line
                              outline
                              hide-details
                              placeholder="Conta bancária"
                              no-data-text="Nenhum registro encontrado"
                              clearable/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="Código de Autorização/Pagamento" v-if="showAuthorizationCode">
                <el-input
                    placeholder="Código de Autorização/Pagamento"
                    v-model="portion.authorization_code">
                </el-input>
              </el-form-item>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="Descrição do Lançamento">
                    <el-input type="textarea"
                              :rows="3"
                              v-model="portion.description"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Observações da Quitação">
                    <el-input type="textarea"
                              :rows="3"
                              v-model="portion.payment_notes"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="Valor Pago"
                                class="is-required mb-1"
                                :class="{ 'el-form-item--error': $v.portion.amount_paid.$error }">
                    <el-input v-model.lazy="portion.amount_paid"
                              v-money="moneyMask"
                              v-if="!isResettingAmount"
                              @input="$v.portion.amount_paid.$touch()"/>
                    <validation-message v-if="$v.portion.amount_paid.$error"/>
                  </el-form-item>
                  <div class="use-balance text-right" v-if="balanceUsed > 0">
                    <span class="text"><strong>Saldo Utilizado</strong> {{ balanceUsed | currencyFormatter }}</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Data/Hora Quitação"
                                class="is-required"
                                :class="{ 'el-form-item--error': $v.portion.pay_date.$error }">
                    <el-date-picker v-model="portion.pay_date"
                                    @input="$v.portion.pay_date.$touch()"
                                    placeholder="Data/Hora de Pagamento"
                                    type="datetime"
                                    format="dd/MM/yyyy HH:mm"
                                    value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                    <validation-message v-if="$v.portion.pay_date.$error"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12" v-if="!portion.multiple">
                  <el-form-item label="Desconto" class="mb-1">
                    <el-input v-model.lazy="portion.discount"
                              v-money="moneyMask"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Quitação Parcial?">
                    <el-switch v-model="isPartialSettled"
                               :disabled="!canBePartialSettled"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO"/>
                  </el-form-item>
                </el-col>
                <el-form-item label="Aplicar Desconto na:" class="pl-2" v-if="!portion.multiple">
                  <el-radio v-model="portion.discount_target" label="portion">Parcela</el-radio>
                  <el-radio v-model="portion.discount_target" label="entry">Cobrança Recorrente</el-radio>
                </el-form-item>
              </el-row>
              <table class="table mt-1 mb-3" v-if="isRevenue">
                <tfoot>
                <tr class="row-success" v-show="portionTotalCredit > 0">
                  <th align="right">Saldo a ser Gerado:</th>
                  <th align="right">+ {{ portionTotalCredit | currencyFormatter }}</th>
                </tr>
                <tr class="row-danger" v-show="portionTotalDebit > 0">
                  <th align="right">Total a Pagar (Em aberto):</th>
                  <th align="right">{{ portionTotalDebit | currencyFormatter }}</th>
                </tr>
                </tfoot>
              </table>
            </el-col>
          </el-row>
          <div class="mb-3 highlight-card -info -small" v-if="isRevenue && planRenewScheduleFixed.exists">
            <h3 class="mb-0 text-center">Renovação de Plano/Pacote</h3>
            <div class="flex-c -jc-sa gap-15 mt-2">
              <div class="text-center">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-form-item label="Data de Fim" class="mb-1">
                      <el-input v-model="planRenewScheduleFixed.start_datetime" readonly/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Data de Início" class="mb-1">
                      <el-input v-model="planRenewScheduleFixed.end_datetime" readonly/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Quantidade de Créditos" class="mb-1">
                  <el-input-number v-model="planRenewScheduleFixed.credits"
                                   :step="1"
                                   :min="1">
                  </el-input-number>
                </el-form-item>
              </div>
              <div class="text-center" v-if="!!planRenewScheduleFixed.schedule.court">
                <div>
                  Quadra: <strong>{{ planRenewScheduleFixed.schedule.court }}</strong>
                </div>
                <div>
                  Horário: <strong>{{ planRenewScheduleFixed.schedule.start_hour }} até
                  {{ planRenewScheduleFixed.schedule.end_hour }}</strong>
                </div>
                <div>
                  Datas: <strong>{{ planRenewScheduleFixed.schedule.dates.join(', ') }}</strong>
                </div>
              </div>
            </div>
          </div>
          <div class="highlight-card -gray -small" v-if="isRevenue && !isPartialSettled">
            <div class="flex-c -jc-ce gap-15">
              <h3 class="mb-0">Gerar Nota Fiscal</h3>
              <el-switch v-model="generateInvoiceData.enabled"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text=""/>
            </div>
            <el-row class="mt-2" :gutter="20" v-if="generateInvoiceData.enabled">
              <el-col :span="12">
                <el-alert type="danger" class="mb-3 small-alert text-center"
                          :closable="false"
                          show-icon
                          v-if="!canGenerateReceipt">
                  <div class="fs-14 pl-2">
                    <strong>Geração de NFCe desabilitada</strong>
                  </div>
                </el-alert>
                <div :class="{'disabled': !canGenerateReceipt}">
                  <h3 class="mb-1">Nota Fiscal de Produto (NFCe)</h3>
                  <el-switch v-model="generateInvoiceData.nfce"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO"/>
                  <h3 class="mt-4 mb-1">Incluir CPF/CNPJ</h3>
                  <el-switch v-model="generateInvoiceData.nfce_include_cpf"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO"/>
                  <h3 class="mt-4 mb-1">Enviar Nota por Email/Whatsapp</h3>
                  <el-switch v-model="generateInvoiceData.nfce_send_notification"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO"/>
                </div>
              </el-col>
              <el-col :span="12">
                <el-alert type="error" class="mb-3 small-alert"
                          :closable="false"
                          show-icon
                          v-if="!canGenerateInvoice">
                  <div class="fs-14 pl-2">
                    <strong>Geração de NFSe desabilitada</strong>
                  </div>
                </el-alert>
                <div :class="{'disabled': !canGenerateInvoice}">
                  <h3 class="mb-1">Nota Fiscal de Serviço (NFSe)</h3>
                  <el-switch v-model="generateInvoiceData.nfse"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO"/>
                  <h3 class="mt-4 mb-1">Incluir CPF/CNPJ</h3>
                  <el-switch v-model="generateInvoiceData.nfse_include_cpf"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO"/>
                  <h3 class="mt-4 mb-1">Enviar Nota por Email/Whatsapp</h3>
                  <el-switch v-model="generateInvoiceData.nfse_send_notification"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO"/>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="!canSettledPortion"
                   @click="settledPortion">
          Quitar
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="showModalGenerateInvoice"
                  :actions-close-btn="false"
                  width="50vw">
      <template v-slot:header>Gerar Nota Fiscal</template>
      <template v-slot:content>
        <el-row :gutter="20">
          <el-col :span="12" v-if="hasCommercialReceiptModule">
            <h3 class="mb-1">Nota Fiscal de Produto (NFCe)</h3>
            <el-switch v-model="generateInvoiceData.nfce"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
            <h3 class="mt-4 mb-1">Incluir CPF/CNPJ</h3>
            <el-switch v-model="generateInvoiceData.nfce_include_cpf"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
            <h3 class="mt-4 mb-1">Enviar Nota por Email/Whatsapp</h3>
            <el-switch v-model="generateInvoiceData.nfce_send_notification"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
          </el-col>
          <el-col :span="12" v-if="hasCommercialInvoiceModule">
            <h3 class="mb-1">Nota Fiscal de Serviço (NFSe)</h3>
            <el-switch v-model="generateInvoiceData.nfse"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
            <h3 class="mt-4 mb-1">Incluir CPF/CNPJ</h3>
            <el-switch v-model="generateInvoiceData.nfse_include_cpf"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
            <h3 class="mt-4 mb-1">Enviar Nota por Email/Whatsapp</h3>
            <el-switch v-model="generateInvoiceData.nfse_send_notification"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
          </el-col>
        </el-row>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="isSubmitted"
                   @click="handleGenerateInvoice">
          Confirmar
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="showModalResumeCustomMessage"
                  :actions-close-btn="false"
                  width="50vw">
      <template v-slot:header>Mensagem Customizada (Será incluída no final da mensagem)</template>
      <template v-slot:content>
        <el-input type="textarea"
                  :rows="4"
                  v-model="resumeCustomMessage"/>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="isSubmitted"
                   @click="handleSendResume">
          Confirmar
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="chargingRules.dialogVisible"
                  :actions-close-btn="false"
                  width="60vw">
      <template v-slot:header>Configurações de Cobrança Automática</template>
      <template v-slot:content>
        <charging-notify :charging-rules-data="chargingRules.rules"/>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="isSubmitted"
                   @click="updateChargingRules">
          Confirmar
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="notifyPortion.dialogVisible"
                  :actions-close-btn="false"
                  width="40vw">
      <template v-slot:header>Notificar Cliente</template>
      <template v-slot:content>
        <el-form v-model="notifyPortion" label-position="top">
          <el-alert type="warning"
                    class="pt-3 pb-3 mb-3"
                    show-icon
                    :closable="false"
                    v-if="!paymentAccount">
            <div slot="title" class="fs-16">
              Para habilitar/gerar o pagamento online cadastre uma conta de pagamentos
            </div>
            <div class="fs-14">
              Acesse o Menu: Sistema -> Meu Clube -> Configurações
              <div>
                ou
                <router-link
                    class="el-tag el-tag--mini el-tag--primary"
                    :to="{name: 'tenant_settings.show', params: {tab: 'config'}}">
                  Clique aqui
                </router-link>
              </div>
            </div>
          </el-alert>
          <el-form-item label="Gerar Pagamento Online?">
            <el-switch
                v-model="notifyPortion.generate_transaction"
                :disabled="!paymentAccount"
                active-color="#13ce66"
                active-text="SIM"
                inactive-color="#ff4949"
                inactive-text="NÃO">
            </el-switch>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="isSubmitted"
                   @click="onNotifyPortion">
          Confirmar
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="editEntry.dialogVisible"
                  :actions-close-btn="false"
                  width="40vw">
      <template v-slot:header>Editar Lançamento</template>
      <template v-slot:content>
        <el-form v-model="editEntry" label-position="top">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="Classificação"
                            class="is-required mb-0"
                            :class="{ 'el-form-item--error': $v.editEntry.classification_id.$error }">
                <el-select v-model="editEntry.classification_id"
                           filterable
                           clearable
                           placeholder="Selecione uma classificação"
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full"
                           @input="$v.editEntry.classification_id.$touch()">
                  <el-option
                      v-for="item in dependenciesClassificationsFiltered"
                      :key="item.id"
                      :label="item.description"
                      :value="item.id">
                  </el-option>
                </el-select>
                <validation-message v-if="$v.editEntry.classification_id.$error"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Vencimento"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.editEntry.due_date.$error }">
                <el-date-picker
                    v-model="editEntry.due_date"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    v-mask="'##/##/####'"
                    placeholder="Vencimento"
                    @input="$v.editEntry.due_date.$touch()">
                </el-date-picker>
                <validation-message v-if="$v.editEntry.due_date.$error"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="Valor"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.editEntry.amount.$error }">
                <el-input v-model.lazy="editEntry.amount"
                          v-money="moneyMask"
                          @input="$v.editEntry.amount.$touch()"/>
                <validation-message v-if="$v.editEntry.amount.$error"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Descrição">
                <el-input type="textarea"
                          :rows="4"
                          v-model="editEntry.description"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="isSubmitted"
                   @click="onEditEntry">
          Confirmar
        </el-button>
      </template>
    </gripo-dialog>

    <show-entry :entry-id="entryIdSelected" :entry-type="entryType" :deleted="filters.deleted"/>
  </div>
</template>
